import React from "react";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";

export default function App() {
  return (
    <>
      <Helmet>
        <title>Devdinos</title>
        <meta name="robots" content="noindex, nofollow" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Devdinos" />
        <meta property="og:description" content="Bringing Your Digital Vision to Life." />
        <meta property="og:image" content="https://devdinos.com/logo1200.png" />
        <meta property="og:url" content="https://devdinos.com/" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:title" content="Devdinos" />
        <meta name="twitter:description" content="Bringing Your Digital Vision to Life." />
        <meta name="twitter:image" content="https://devdinos.com/logo1200.png" />
      </Helmet>
      <Landing />
    </>
  );
}

