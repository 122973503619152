import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import ProjectImg1 from "../../assets/img/projects/1.png";
import ProjectImg2 from "../../assets/img/projects/2.png";
import ProjectImg3 from "../../assets/img/projects/3.png";
import ProjectImg4 from "../../assets/img/projects/4.png";
import ProjectImg5 from "../../assets/img/projects/5.png";
import ProjectImg6 from "../../assets/img/projects/6.png";
import AddImage2 from "../../assets/img/add/add2.svg";

export default function Projects() {
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Projects</h1>
            <p className="font13">
            Take a look at some of the projects we've brought to life. Each project is a testament to our commitment to quality, creativity, and client satisfaction.
              <br />
              From intricate web applications to visually stunning websites, our portfolio showcases the breadth and depth of our capabilities.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <a href="https://tinyurl.com/yc6zu49u" target="_blank">
                <ProjectBox
                  img={ProjectImg1}
                  title="Ausstellungskuechen"
                  text="Find your new kitchen here, displayed in a showroom, for an unbelievably low price."
                  action={''}
                />
              </a>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <a href="https://tinyurl.com/5bz5z7yu" target="_blank">
              <ProjectBox
                img={ProjectImg2}
                title="More future per m²"
                text="With around 27,500 apartments in the top cities, the Adler Group is one of Germany's leading residential real estate companies."
                action={''}
              />
            </a>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <a href="https://tinyurl.com/yruttzyt" target="_blank">
                <ProjectBox
                  img={ProjectImg3}
                  title="Reddy Küchen"
                  text="Find your new kitchen here, displayed in a showroom, for an unbelievably low price."
                  action={''}
                />
              </a>
            </div>
          </div>
          <div className="row textCenter" style={{paddingBottom: '60px'}}>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <a href="https://tinyurl.com/4bntmdsu" target="_blank">
                <ProjectBox
                  img={ProjectImg4}
                  title="Kitchen studio & joinery Groß in Munich"
                  text="Individually planned fitted kitchens, custom-made furniture, professional interior design: For three generations, our family business has stood for passion and quality."
                  action={''}
                />
              </a>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <a href="https://tinyurl.com/5a4v5sta" target="_blank">
                <ProjectBox
                  img={ProjectImg5}
                  title="Solutions as individual as your passion."
                  text="For everyone who wants to focus on the essentials, minimize risks, seize opportunities and optimize processes."
                  action={''}
                />
              </a>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <a href="https://tinyurl.com/32s6dnrc" target="_blank">
              <ProjectBox
                img={ProjectImg6}
                title="Your partner for mobile refrigeration"
                text="Rent from an expert."
                action={''}
              />
            </a>
            </div>
          </div>
          {/* <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Load More" action={() => alert("clicked")} />
            </div>
          </div> */}
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8 blur-gb" src={AddImage2} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">A few words about company</h4>
              <h2 className="font30 extraBold">A Study of Creativity</h2>
              <p className="font13">
              Creativity is at the heart of everything we do. Our approach to web development is not just about writing code; it's about creating beautiful, intuitive, and effective digital experiences. We believe in pushing the boundaries of what's possible and delivering solutions that are as innovative as they are functional.
              </p>
              {/* <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "190px" }}>
                  <FullButton title="Get Started" action={() => alert("clicked")} />
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton title="Contact Us" action={() => alert("clicked")} border />
                </div>
              </ButtonsRow> */}
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  margin 40px 0;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    margin-top: 55px;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
