import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";

export default function Blog() {
  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Why Choose Us?</h1>
            <p className="font13">
            Your Trusted Partner for Innovative and Tailored Web Solutions
            </p>
          </HeaderInfo>
          <div className="d-grid grid-3 grid-xs-3 gap-20 mb-60">
            <div className="w-100">
              <BlogBox
                title="Innovative Solutions"
                text="We merge creativity with cutting-edge technology to deliver digital experiences that are not only visually stunning but also functionally exceptional. Our solutions are designed to make your brand stand out in a crowded digital landscape."
                tag="company"
                author="Luke Skywalker, 2 days ago"
              />
            </div>
            <div className="w-100">
              <BlogBox
                title="Tailored Approach"
                text="Every project we undertake is uniquely crafted to align with your specific goals and vision. We take the time to understand your needs and preferences, ensuring that the final product is a true reflection of your brand and its objectives."
                tag="company"
                author="Luke Skywalker, 2 days ago"
              />
            </div>
            <div className="w-100">
              <BlogBox
                title="Expert Team"
                text="Our team comprises seasoned professionals with deep expertise in both front-end and back-end development. Whether it's mastering HTML, CSS, JavaScript, React, Vue.js, PHP, Laravel, SQL, TYPO3, WordPress, or Shopify, we bring a wealth of knowledge to every project."
                tag="company"
                author="Luke Skywalker, 2 days ago"
              />
            </div>
            <div className="w-100">
              <BlogBox
                title="Seamless Experience"
                text="We focus on creating user-friendly interfaces that ensure a smooth and engaging experience for your audience. Our attention to detail in design and functionality enhances usability and encourages interaction."
                tag="company"
                author="Luke Skywalker, 2 days ago"
              />
            </div>
            <div className="w-100">
              <BlogBox
                title="Commitment to Quality"
                text="We are dedicated to delivering top-notch quality in every aspect of our work. From the initial concept to the final implementation, we ensure that each element of your project meets the highest standards of excellence."
                tag="company"
                author="Luke Skywalker, 2 days ago"
              />
            </div>
            <div className="w-100">
              <BlogBox
                title="Forward-Thinking"
                text="We stay ahead of industry trends and technological advancements to keep your digital presence at the cutting edge. Our forward-thinking approach ensures that your website or platform is both innovative and future-ready."
                tag="company"
                author="Luke Skywalker, 2 days ago"
              />
            </div>
            <div className="w-100">
              <BlogBox
                title="Reliable Partnership"
                text="We believe in building strong, lasting relationships with our clients. Our commitment to transparent communication, dependable delivery, and ongoing support ensures that you have a trusted partner throughout your digital journey."
                tag="company"
                author="Luke Skywalker, 2 days ago"
              />
            </div>
            <div className="w-100">
              <BlogBox
                title="Proven Results"
                text="Our track record speaks for itself. We have successfully delivered a wide range of projects, from simple websites to complex e-commerce platforms, consistently achieving outstanding results and client satisfaction."
                tag="company"
                author="Luke Skywalker, 2 days ago"
              />
            </div>
            <div className="w-100">
              <BlogBox
                title="Comprehensive Support"
                text="Beyond development, we offer ongoing support and maintenance to ensure that your digital solutions continue to perform optimally and adapt to changing needs."
                tag="company"
                author="Luke Skywalker, 2 days ago"
              />
            </div>
          </div>
          {/* <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Load More" />
            </div>
          </div> */}
        </div>
      </div>
      <div className="lightBg" style={{padding: '50px 0'}}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">What Our Clients Say About Us</h1>
            <p className="font13">
            Don't just take our word for it. Hear what our clients have to say about working with us. <br />We've built a reputation for delivering exceptional results and maintaining strong, collaborative relationships with our clients.
            </p>
          </HeaderInfo>
          <TestimonialSlider />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;