import React, { useEffect } from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings} className="testimonialSlider">
        {testimonialData.map((testimonial, index) => (
          <LogoWrapper key={index}>
            <TestimonialBox text={testimonial.text} author={testimonial.author} />
          </LogoWrapper>
        ))}
      </Slider>
    </div>
  );
}

const testimonialData = [
  {
    text: "Working with DevDinoz has been an absolute pleasure. The team's dedication and expertise are unmatched. We've seen significant improvements in our business operations thanks to their innovative solutions.",
    author: "Jane Doe",
  },
  {
    text: "DevDinoz exceeded our expectations in every way. Their attention to detail and commitment to excellence is evident in every project they undertake. We highly recommend them!",
    author: "John Smith",
  },
  {
    text: "The level of professionalism and quality of service provided by DevDinoz is truly exceptional. They understand our needs and consistently deliver outstanding results.",
    author: "Emily Johnson",
  },
  {
    text: "We couldn't be happier with our decision to partner with DevDinoz. Their team is knowledgeable, responsive, and always willing to go the extra mile to ensure our success.",
    author: "Michael Brown",
  },
  {
    text: "Die Zusammenarbeit mit DevDinoz war eine hervorragende Erfahrung. Das Team hat unsere Erwartungen übertroffen und uns maßgeschneiderte Lösungen geliefert, die perfekt auf unsere Bedürfnisse abgestimmt sind.",
    author: "Hans Müller",
  },
  {
    text: "Wir sind äußerst zufrieden mit den Dienstleistungen, die wir von DevDinoz erhalten haben. Sie sind nicht nur technisch versiert, sondern auch sehr kundenorientiert und zuverlässig.",
    author: "Klara Schmidt",
  },
];

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  height: 100%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
