import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Assets
import php from "../../assets/img/clients/php-logo.svg";  //../../assets/img/clients/html.svg
import laravel from "../../assets/img/clients/laravel.svg";
import react from "../../assets/img/clients/react-js.svg";
import redux from "../../assets/img/clients/redux.svg";
import vue from "../../assets/img/clients/vue-js.svg";
import wordpress from "../../assets/img/clients/wordpress.svg";
import shopify from "../../assets/img/clients/shopify.svg";
import typo3 from "../../assets/img/clients/typo3.svg";
import tailwindcss from "../../assets/img/clients/tailwindcss.svg";
import mysql from "../../assets/img/clients/mysql.svg";
import bootstrap from "../../assets/img/clients/bootstrap.svg";


export default function ClientSlider() {
  const settings = {
    infinite: true,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 2,
    arrows: false,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <h2 className="uppercase textCenter font30">Wondering about the right tech stack?</h2>
      <p className="textCenter">Ensure perfect tech stack choices every time, with no compromises.</p>
      <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={php} alt="php" />
          <TagStyle>PHP</TagStyle>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={laravel} alt="laravel" />
          <TagStyle>Laravel</TagStyle>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={react} alt="react" />
          <TagStyle>React</TagStyle>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={redux} alt="redux" />
          <TagStyle>Redux</TagStyle>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={vue} alt="vue" />
          <TagStyle>Vue</TagStyle>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={wordpress} alt="wordpress" />
          <TagStyle>Wordpress</TagStyle>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={shopify} alt="shopify" />
          <TagStyle>Shopify</TagStyle>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={typo3} alt="typo3" />
          <TagStyle>Typo3</TagStyle>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={tailwindcss} alt="tailwindcss" />
          <TagStyle>Tailwind Css</TagStyle>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={mysql} alt="mysql" />
          <TagStyle>mysql</TagStyle>
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <ImgStyle src={bootstrap} alt="bootstrap" />
          <TagStyle>Bootstrap</TagStyle>
        </LogoWrapper>
      </Slider>
    </div>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 100%;
  height: 100px;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
const ImgStyle = styled.img`
  width: 100%;
  height: 100%;
  padding: 10%;
  max-width: 125px;
  margin: 0 auto;
`;
const TagStyle = styled.p`
 text-align: center;
  padding: 0 10px;
  opacity: 0.5;
`;