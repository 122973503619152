import React, { useRef, useState } from "react";
import styled from "styled-components";
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import Mail from "../../assets/img/mail.svg";


export default function Contact() {
  const form = useRef();
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [isCaptchaSolved, setIsCaptchaSolved] = useState(false);
  const [formFields, setFormFields] = useState({
    user_name: "",
    user_email: "",
    user_subject: "",
    message: ""
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  // Check if all form fields are filled
  const isFormComplete = Object.values(formFields).every(field => field.trim() !== "");

  const handleRecaptcha = (token) => {
    setRecaptchaToken(token);
    setIsCaptchaSolved(true); // Enable the button after CAPTCHA is solved
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setFormFields(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const sendEmail = (e) => {
    setIsCaptchaSolved(false);
    e.preventDefault();

    if (!recaptchaToken) {
      console.error('reCAPTCHA token is missing');
      return;
    }

    emailjs
      .sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, {
        publicKey: process.env.REACT_APP_PUBLICKEY,
        'g-recaptcha-response': recaptchaToken,
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setRecaptchaToken(null);
          setIsCaptchaSolved(false);
          setFormFields({
            user_name: "",
            user_email: "",
            user_subject: "",
            message: ""
          });
          setShowSuccessMessage(true);
          setTimeout(() => setShowSuccessMessage(false), 3000);
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <Wrapper id="contact">
      <div className="container">
        <div className="d-grid grid-2 grid-sm-2">
          <div>
            <div>
              <HeaderInfo>
                <h1 className="font40 extraBold">Let's get in touch</h1>
                <p className="font13">
                  We’d love to hear from you! Whether you have questions, feedback, or just want to connect, reaching out is easy. <br />
                  Our team is here to provide support, answer your inquiries, and discuss how we can assist you.<br />
                  Simply fill out the form below, and we’ll get back to you as soon as possible.
                </p>
              </HeaderInfo>
              <div style={{ paddingBottom: "30px" }}>
                <div>
                  <Form ref={form} onSubmit={sendEmail}>
                    <label className="font13">First name:</label>
                    <input
                      type="text"
                      id="fname"
                      name="user_name"
                      className="font14 khulaFont"
                      value={formFields.user_name}
                      onChange={handleFieldChange}
                      required="true"
                    />
                    <label className="font13">Email:</label>
                    <input
                      type="text"
                      id="email"
                      name="user_email"
                      className="font14 khulaFont"
                      value={formFields.user_email}
                      onChange={handleFieldChange}
                      required="true"
                    />
                    <label className="font13">Subject:</label>
                    <input
                      type="text"
                      id="subject"
                      name="user_subject"
                      className="font14 khulaFont"
                      value={formFields.user_subject}
                      onChange={handleFieldChange}
                      required="true"
                    />
                    <label className="font13">Message:</label>
                    <textarea
                      rows="4"
                      cols="50"
                      id="message"
                      name="message"
                      className="font14 khulaFont"
                      value={formFields.message}
                      onChange={handleFieldChange}
                      required="true"
                    />
                    {process.env.REACT_APP_SITEKEY && isFormComplete ? (
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SITEKEY}
                        onChange={handleRecaptcha}
                      />
                    ) : (
                      <p style={{color: "red"}}>Please fill in all fields to enable CAPTCHA.</p>
                    )}
                    <SumbitWrapper className="flex">
                      <ButtonInput
                        type="submit"
                        className={`pointer animate radius8 ${!isCaptchaSolved ? 'disabled' : ''}`}
                        style={{ maxWidth: "220px", marginTop: "50px" }}
                        disabled={!isCaptchaSolved || !isFormComplete}
                      >
                        Send Message
                      </ButtonInput>
                    </SumbitWrapper>
                  </Form>
                </div>
                {/* <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
                  <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
                    <ContactImgBox>
                      <img src={ContactImg1} alt="office" className="radius6" />
                    </ContactImgBox>
                    <ContactImgBox>
                      <img src={ContactImg2} alt="office" className="radius6" />
                    </ContactImgBox>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div style={{ marginTop: "100px" }}>
                      <img src={ContactImg3} alt="office" className="radius6" />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="d-sm-none d-flex align-items-center">
            <img src={Mail} />
          </div>
        </div>
      </div>
      {showSuccessMessage && (
        <SuccessMessage>
          Message sent successfully
        </SuccessMessage>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const SuccessMessage = styled.div`
    position: fixed;
    top: 91px;
    right: 14px;
    background: #51ad51;
    width: auto;
    padding: 10px 21px 8px 21px;
    border-radius: 8px;
    margin: 0;
    color: white;
    line-height: 26px;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 768px) {
    text-align: center;
  }
`;
const Form = styled.form`
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
    border-radius: 0;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.button`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
