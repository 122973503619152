import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
import Scope from "../Sections/Scope";
// Assets
import AddImage1 from "../../assets/img/add/1.svg";
import AddImage2 from "../../assets/img/add/2.svg";
import AddImage4 from "../../assets/img/add/4.svg";
//import FullStack from "../../assets/img/add/full-stack-development.png";

export default function Services() {
  return (
    <Wrapper id="services">
      {/* <Scope /> */}
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Services</h1>
            <p className="font13 service-text">
            At Devdeinos, we specialize in delivering top-notch web development solutions tailored to meet your business needs. From dynamic websites and e-commerce platforms to custom web applications and responsive designs, our expert team combines innovative technologies with creative design to bring your vision to life. Whether you’re looking to enhance your online presence or build a robust digital solution from scratch, we’re here to help you succeed in the digital landscape.
            </p>
          </HeaderInfo>
          <ServiceBoxRow className="d-grid grid-md-4 grid-xs-4 grid-4 gap-10">
            <ServiceBoxWrapper>
              <ServiceBox
                
                icon="roller"
                title="Full-Stack Development"
                subtitle="We offer comprehensive website development, covering both the front-end and back-end. Our expertise includes creating visually appealing interfaces (front-end) and ensuring robust functionality and data management (back-end)."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title="Responsive Design"
                subtitle="Our designs ensure that your website looks and performs well on all devices, including desktops, tablets, and smartphones. We use responsive design techniques to adapt the layout to various screen sizes."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="Payment Gateway"
                subtitle="Secure and seamless payment processing is crucial for online stores. We integrate payment gateways to enable various payment methods, ensuring smooth transactions for your customers."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox icon="printer" title="Analytics and Reporting" subtitle="Understanding user behavior and website performance is crucial for growth. We integrate analytics tools to track metrics, analyze data, and generate reports" />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <div className="d-grid grid-2 grid-xs-2 gap-20 mb-7">
                  <div>
                    <h4 className="font25 semiBold">Who We Are</h4>
                    <p className="font13">
                    We are a passionate team of web developers and designers committed to transforming digital visions into reality.
                    </p>
                  </div>
                  <div>
                    <h4 className="font25 semiBold">What We Do</h4>
                    <p className="font13">
                    We create seamless, dynamic web experiences, from bespoke websites to comprehensive e-commerce platforms, designed to captivate and engage.
                    </p>
                  </div>
                </div>
                <div>
                  <h4 className="font25 semiBold">How We Do It</h4>
                  <p className="font13">
                  Utilizing the latest technologies—HTML, CSS, JavaScript, React, Vue.js, Redux, PHP, Laravel, SQL, TYPO3, WordPress, and Shopify—we deliver customized solutions that perfectly align with your unique needs and goals.
                  </p>
                </div>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={AddImage2} alt="office" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter contentEnd">
                    {/* <AddImgWrapp3>
                      <img src={AddImage3} alt="office" />
                    </AddImgWrapp3> */}
                    <AddImgWrapp4>
                      <img src={AddImage4} alt="office" style={{padding: "10px"}}/>
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    margin-top: 2rem;
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    display: none;
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2px);
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2px);
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2px);
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2px);
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;